const BestDeals = () => import("./pages/BestDeals.vue");
const ThankYou = () => import("./pages/ThankYou.vue");
const ProductInterest = () => import("./pages/ProductInterest.vue");
const DealsFeature = () => import("./pages/DealsFeature.vue");
const ShoppingFactor = () => import("./pages/ShoppingFactor.vue");
const BlsCampaign = () => import("./pages/BlsCampaign.vue");
const TcExpectations = () => import("./pages/TcExpectations.vue");
const PriceComparison = () => import("./pages/PriceComparison.vue");
const BuyingHesitation = () => import("./pages/BuyingHesitation.vue");

export const BlsRoutes = [
  {
    path: "/tc_bls",
    component: BlsCampaign,
    meta: {
      title: "Get Deals Tailored for You!"
    },
    children: [
      {
        path: "products",
        component: ProductInterest
      },
      {
        path: "shopping_factor",
        component: ShoppingFactor
      },
      {
        path: "tc_expectation",
        component: TcExpectations
      },
      {
        path: "deal_feature",
        component: DealsFeature
      },
      {
        path: "price_comparison",
        component: PriceComparison
      },
      {
        path: "best_deals",
        component: BestDeals
      },
      {
        path: "buying_hesitation",
        component: BuyingHesitation
      },
      {
        path: "thankyou",
        component: ThankYou
      }
    ]
  }
];
