import { SpriteRoutes } from "./Sprite/routes";
import { FlarieRoutes } from "./FlarieGames/routes";
// import { DellRoutes } from "./Dell/routes";
import { ThumsupRoutes } from "./Thumsup/routes";
import { NipponRoutes } from "./Nippon/routes";
// import { MadburyRoutes } from "./Madbury/routes";
import { SaffolaRoutes } from "./Saffola/routes";
import { BlsRoutes } from "./DpandaBls/routes";
import { HSBCRoutes } from "./HSBC/routes";

// CadburyRoutes and MadburyRoutes are created for POC
export const LeadClientRoutes = [
  ...FlarieRoutes,
  // ...DellRoutes,
  ...SpriteRoutes,
  ...NipponRoutes,
  ...ThumsupRoutes,
  ...SaffolaRoutes,
  ...BlsRoutes,
  ...HSBCRoutes
];
