const ThankYou = () => import("./pages/ThankYou.vue");
const UserDetailsForm = () => import("./pages/UserDetailsForm.vue");

export const HSBCRoutes = [
  {
    path: "/hsbc",
    component: UserDetailsForm,
    meta: {
      title: "HSBC!"
    }
  },
  {
    path: "/hsbc/thankyou",
    component: ThankYou
  }
];
